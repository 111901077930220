import axios from "axios";
import { getAuth } from "./identity.service";

export const postWithAuth = (url, entity) => {
    return new Promise((resolve, _reject) => {
        const auth = getAuth();
        const headers = {
            "content-type": "application/json",
            "x-access-token": auth.token,
            "x-platform": 'adminWeb'
        };
        axios
            .post(url, entity, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve({ status: true, data: response.data });
                }
            })
            .catch((ex) => {
                resolve({ status: false, message: ex.message });
            });
    });
};

export const getWithAuth = (url, token = "") => {
    if (!token) token = getAuth().token;
    const headers = {
        "content-type": "application/json",
        "x-access-token": token,
        "x-platform": 'adminWeb'
    };
    return new Promise((resolve, _reject) => {
        axios
            .get(url, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve({ status: true, data: response.data });
                }
            })
            .catch((ex) => {
                resolve({ status: false, error: ex.message });
            });
    });
};

export const deleteWithAuth = (url, entity) => {
    const auth = getAuth();

    const headers = {
        "content-type": "application/json",
        "x-access-token": auth.token,
        "x-platform": 'adminWeb'
    };

    return new Promise((resolve, _reject) => {
        axios
            .delete(url, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve({ status: true, data: response.data });
                }
            })
            .catch((ex) => {
                resolve({ status: false, message: ex.message });
            });
    });
};

export const putWithAuth = (url, data) => {
    const auth = getAuth();
    const headers = {
        "content-type": "application/json",
        "x-access-token": auth.token,
        "x-platform": 'adminWeb'
    };
    return new Promise((resolve, _reject) => {
        axios
            .put(url, data, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve({ status: true, data: response.data });
                }
            })
            .catch((ex) => {
                resolve({ status: false, message: ex.message });
            });
    });
};

export const postWithOutAuth = (url, entity) => {
    return new Promise((resolve, _reject) => {
        const headers = {
            "content-type": "application/json",
            "x-platform": 'adminWeb'
        };
        axios
            .post(url, entity, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve({ status: true, data: response.data });
                }
            })
            .catch((ex) => {
                resolve({ status: false, message: ex.message });
            });
    });
};