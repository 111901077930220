import WithAuth from "@/components/Auth/WithAuth";
import axios from "axios";
import { getLayout } from "components/Layout/LayoutConfiguration";
import { getAuth, getRefreshToken, removeAuth, removeRT, setAuth } from "services/identity.service";
import "../global-styles.css";
import "../public/scss/theme.scss";

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  const originalRequest = error.config
  if (originalRequest.url.includes('/auth/refresh')) {
    removeAuth();
    removeRT();
    if (typeof window !== 'undefined') {
      window.open("/login", "_self");
    }
    return Promise.reject(error);
  }
  if (error.response.status === 401) {
    const auth = getAuth();
    const refreshToken = getRefreshToken();
    return axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/auth/refresh`,
      {
        refreshToken: refreshToken, id: auth.id
      }
    ).then((res) => {
      setAuth({ ...auth, token: res.data.token })
      if (typeof window !== 'undefined') {
        window.location.reload();
      }
    }).catch((error) => {
      removeAuth();
      removeRT();
      if (typeof window !== 'undefined') {
        window.open("/login", "_self");
      }
      return Promise.reject(error);
    })
  }
});
function MyApp({ Component, pageProps, flagsmithState, router }) {
  let layout = getLayout(router.pathname);
  return (
    <WithAuth router={router}>
      {layout({ children: <Component {...pageProps} /> })}
    </WithAuth>
  );
}


export default MyApp;
