import { getAuth } from "services/identity.service";
const auth = getAuth();
let role = auth ? auth.role : "";
let leftNavigation = {
  "USER CONTROL": [
    {
      order: 1,
      name: "Admin Control",
      expanded: false,
      icon: <i className="bx bx-grid-alt"></i>,
      children: [
        {
          order: 1,
          name: "Users",
          route: "/members",
          role: ["super_admin"],
        },
      ],
    },
  ],
  MENU: [
    {
      order: 1,
      name: "Home Page",
      icon: <i className="bx bx-store"></i>,
      children: [
        {
          order: 1,
          name: "Main Banners",
          route: "/banners",
          role: ["super_admin", "admin"],
        },
        {
          order: 2,
          name: "Secondary Banners",
          route: "/secondary-banners",
          role: ["super_admin", "admin"],
        },
        {
          order: 3,
          name: "Product Detail Page",
          children: [
            {
              order: 1,
              name: "Sections",
              children: [
                {
                  order: 1,
                  name: "Disable Sections",
                  route: "/disable-section",
                  role: ["super_admin", "admin"],
                },
              ]

            },
            {
              order: 2,
              name: "InfoCards",
              children: [
                {
                  order: 1,
                  name: "Global Info Cards",
                  route: "/info-cards",
                  role: ["super_admin", "admin"],
                },
                {
                  order: 2,
                  name: "Products Info Cards",
                  route: "/products-info-cards",
                  role: ["super_admin", "admin"],
                },
              ]
            },
            {
              order: 2,
              name: 'Similar-Products',
              route: '/similar-products',
              role: ['super_admin', 'admin']
            },
            {
              order: 4,
              name: "Cart Page",
              children: [
                {
                  order: 1,
                  name: 'Cart-Similar-Products',
                  route: '/cart-similar-product',
                  role: ['super_admin', 'admin']
                }
              ]
            },
            {
              order: 5,
              name: "Coupons",
              children: [
                {
                  order: 1,
                  name: 'Coupons',
                  route: '/coupons',
                  role: ['super_admin', 'admin']
                },
                {
                  order: 2,
                  name: "Marketing Line",
                  route: "/marketing-line",
                  role: ["super_admin", "admin"],
                },

              ]
            }
          ]
        }


      ],
    },
  ],
};

const format = (item) => {
  if (item.role && item.role.some((name) => name === role)) {
    return item;
  }
  if (item.role) return null;
  item.children = item.children.map((ele) => {
    return format(ele)
  }).filter(element => element);
  if (item.children.length) return item;
  return null;
}
Object.keys(leftNavigation).forEach((nav) => {
  leftNavigation[nav] = leftNavigation[nav].map((item) => {
    return format(item)
  }).filter((ele) => ele);
  if (!leftNavigation[nav].length) {
    delete leftNavigation[nav];
  }
})
console.log(leftNavigation)
export default leftNavigation;
